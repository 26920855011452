import { format } from 'date-fns';
import { ReactElement } from 'react';
import { Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { MLData } from '../../models/MLData';
import route from '../../route';

type Props = {
  mlData: MLData;
};

export const MLDataInfo = (props: Props): ReactElement => {
  const mlData = props.mlData;
  return (
    <>
      <Table bordered hover size="sm" responsive="sm">
        <tbody>
          <tr>
            <th>key</th>
            <td>
              <Link to={route.aws.mlDataGraph2.to(mlData.filename)}>
                {mlData.filename}
              </Link>
            </td>
          </tr>
          <tr>
            <th>ラベル</th>
            <td>{mlData.label}</td>
          </tr>
          <tr>
            <th>ユーザー</th>
            <td>
              <code>{mlData.uid}</code>
            </td>
          </tr>
        </tbody>
      </Table>
      <h2> ラベル </h2>
      <Table bordered hover size="sm" responsive="sm">
        <thead>
          <tr>
            <th>timestamp</th>
            <th>開始</th>
            <th>終了</th>
            <th>workoutId</th>
            <th>回数</th>
          </tr>
        </thead>

        <tbody>
          {mlData.labels.map((label) => (
            <tr>
              <td>
                <Link to={route.aws.labelDetail.to(label.timestamp)}>
                  {label.timestamp}
                </Link>
              </td>
              <td>{label.start}</td>
              <td>{label.end}</td>
              <td>{label.workoutId}</td>
              <td>{label.countedTimeStamps?.length ?? 0}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {mlData.log.map((logItem) => {
        switch (logItem.type) {
          case 'INITIAL':
            return (
              <>
                <>
                  <h5>FreedomMode</h5>
                  <Table bordered hover size="sm" responsive="sm">
                    <thead>
                      <tr>
                        <th>開始</th>
                        <th>終了</th>
                        <th colSpan={2}>workoutId</th>
                        <th colSpan={2}>回数</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logItem.list &&
                        logItem.list.map((workout, i) => (
                          <tr>
                            <td>
                              <code>
                                {format(
                                  new Date(workout.begin),
                                  'HH:mm:ss.SSSS',
                                )}
                              </code>
                            </td>
                            <td>
                              <code>
                                {format(new Date(workout.end), 'HH:mm:ss.SSSS')}
                              </code>
                            </td>
                            <td>
                              {workout.recognizedWorkout && (
                                <code>{workout.recognizedWorkout}</code>
                              )}
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}
                              >
                                <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={
                                    <Tooltip id={`tooltipId${i}`}>
                                      {workout.recognizedWorkouts &&
                                        workout.recognizedWorkouts.map(
                                          (item) => (
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              {item.workoutId}(
                                              {item.probality.toFixed(3)})
                                            </div>
                                          ),
                                        )}
                                    </Tooltip>
                                  }
                                >
                                  <div>
                                    {workout.recognizedWorkouts &&
                                      workout.recognizedWorkouts
                                        .slice(0, 3)
                                        .map((item) => (
                                          <div
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                            }}
                                          >
                                            <code>{item.workoutId}</code>
                                            <div>
                                              (
                                              <code>
                                                {item.probality.toFixed(3)}
                                              </code>
                                              )
                                            </div>
                                          </div>
                                        ))}
                                  </div>
                                </OverlayTrigger>
                              </div>
                            </td>
                            <td>
                              <code>{workout.confirmedWorkout}</code>
                            </td>
                            <td>
                              <code>{workout.recognizedCount}</code>
                            </td>
                            <td>
                              <code>{workout.confirmedCount}</code>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              </>
            );
          default:
            return null;
        }
      })}
    </>
  );
};
