import { api } from '../api';

export type Label = {
  workoutId: string;
  variant: string;
  key: string;
  start: string;
  end: string;
  count?: number;
  countedTimeStamps?: string[];
  uid?: string;
};

export type SavedLabel = Label & {
  timestamp: string;
};

export const createLabel = async (label: Label): Promise<void> => {
  await api.post<void>('/admin/label', label);
  return;
};

export const updateLabel = async (label: SavedLabel): Promise<void> => {
  await api.put<void>(`/admin/label`, label);
  return;
};

export const getLabel = async (timestamp: string): Promise<SavedLabel> => {
  const res = await api.get<SavedLabel>(`/admin/label/${timestamp}`);
  return res.data;
};

export const getAllLabels = async (): Promise<SavedLabel[]> => {
  const res = await api.get<SavedLabel[]>(`/admin/label/`);
  return res.data;
};

export const deleteLabel = async (timestamp: string): Promise<void> => {
  await api.delete<void>(`/admin/label/${timestamp}`);
  return;
};

export const getLabelCsv = async (
  timestamp: string,
): Promise<{ get_url: string }> => {
  const res = await api.get<{ get_url: string }>(
    `/admin-data/label/download-csv/${timestamp}`,
  );
  return res.data;
};
