export const nodeIsProd = process.env.NODE_ENV === 'production';

type ApiEnvType = 'prod' | 'stg' | 'dev';
export const ApiEnv: ApiEnvType =
  (process.env.REACT_APP_API_ENV as ApiEnvType) || 'dev';

export const API_URL = (() => {
  switch (ApiEnv) {
    case 'prod':
      return 'https://api.v1.muscle-supporter.com';
    default:
      return 'https://dev.api.v1.muscle-supporter.com';
  }
})();

export const firebaseConfig = (() => {
  switch (ApiEnv) {
    case 'prod':
      return {
        apiKey: 'AIzaSyBc7A6JAWF3mi28rS5o14KiJ0dU3uwNfKc',
        authDomain: 'muscle-supporter-prod-4888a.firebaseapp.com',
        projectId: 'muscle-supporter-prod-4888a',
        storageBucket: 'muscle-supporter-prod-4888a.appspot.com',
        messagingSenderId: '919677503847',
        appId: '1:919677503847:web:dc79468fc11baeee2987c2',
        measurementId: 'G-V0KFNM0VXX',
      };
    default:
      return {
        apiKey: 'AIzaSyBv8jzDt3bZmvVkR3CZV1H01J477DoJIWE',
        authDomain: 'muscle-supporter-dev-9f3b8.firebaseapp.com',
        projectId: 'muscle-supporter-dev-9f3b8',
        storageBucket: 'muscle-supporter-dev-9f3b8.appspot.com',
        messagingSenderId: '88625838730',
        appId: '1:88625838730:web:09a80e55e65edd70fbcb73',
        measurementId: 'G-NDZ6FBTB8D',
      };
  }
})();
