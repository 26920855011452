import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { ReactElement, useState } from 'react';
import { Breadcrumb, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getAllWorkouts, Workout } from '../../../models/Workout';
import route from '../../../route';
import { Loading } from '../../common/Loading';
import { getTargetMuscleIndex, getTargetMuscleName } from './muscleCategories';

export const WorkoutList = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [list, setList] = useState<Workout[]>([]);

  const reload = async () => {
    setIsLoading(true);
    setList(await getAllWorkouts());
    setIsLoading(false);
  };

  const [sortby, setSortby] = useState<string>('firstTM');
  const retSortFunc = (sortby: string) => {
    if (sortby === 'firstTM') {
      return (a: Workout, b: Workout) => {
        return (
          getTargetMuscleIndex(a.targetMuscle[0]) -
          getTargetMuscleIndex(b.targetMuscle[0])
        );
      };
    } else if (sortby === 'id') {
      return (a: Workout, b: Workout) => {
        return a.workoutId.localeCompare(b.workoutId);
      };
    }
  };

  useEffect(() => {
    reload();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>筋トレ一覧</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h1>筋トレ一覧</h1>
        <div>
          <Button
            href="https://github.com/kosuke-gin-ebi/mitou-api/blob/main/api/preset.py"
            size="sm"
            target="_blank"
            className="mx-1"
            variant="secondary"
          >
            <FontAwesomeIcon icon={faGithub} className="me-1" />
            GitHub
          </Button>
          <Button onClick={reload} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Table bordered hover size="sm" responsive="sm">
            <thead>
              <tr>
                <th onClick={() => setSortby('id')}>id ▼</th>
                <th>表示名</th>
                <th onClick={() => setSortby('firstTM')}>
                  First targetMuscle ▼
                </th>
                <th>desc.len</th>
                <th>tm.num</th>
                <th>variants</th>
              </tr>
            </thead>
            <tbody>
              {list.sort(retSortFunc(sortby)).map(
                (data) =>
                  data.workoutId && (
                    <tr key={data.workoutId}>
                      <td>
                        <Link
                          to={route.firebase.presetWorkoutDetail.to(
                            data.workoutId,
                          )}
                        >
                          {data.workoutId}
                        </Link>
                      </td>
                      <td>{data.displayName}</td>
                      <td>
                        {data.targetMuscle
                          ? getTargetMuscleName(data.targetMuscle[0])
                          : 0}
                      </td>
                      <td>{data.description ? data.description.length : 0}</td>
                      <td>
                        {data.targetMuscle ? data.targetMuscle.length : 0}
                      </td>
                      <td>
                        {data.variants
                          .map((variant) => variant.name)
                          .join(' / ')}
                      </td>
                    </tr>
                  ),
              )}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};
