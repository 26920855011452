import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  getAllWorkouts,
  Workout,
  flattenVariant,
  WorkoutVariableFlattened,
} from '../models/Workout';

export type PresetWorkoutListState = {
  workouts: Workout[];
  workoutsWithVariant: WorkoutVariableFlattened[];
  isLoading: boolean;
};

const initialState: PresetWorkoutListState = {
  workouts: [],
  workoutsWithVariant: [],
  isLoading: false,
};

export const fetchPresetWorkouts = createAsyncThunk(
  'fetchPresetWorkoutsThunk',
  async () => getAllWorkouts(),
);

export const presetWorkoutListSlice = createSlice({
  name: 'presetWorkoutList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPresetWorkouts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPresetWorkouts.fulfilled, (state, action) => {
      state.workouts = action.payload;
      state.workoutsWithVariant = flattenVariant(action.payload);
      state.isLoading = false;
    });
    builder.addCase(fetchPresetWorkouts.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});
