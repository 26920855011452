import { ReactElement } from 'react';
import { Spinner } from 'react-bootstrap';

export const Loading = (): ReactElement => {
  return (
    <div className="p-5 d-flex justify-content-center">
      <Spinner animation="border" role="status" />
    </div>
  );
};
