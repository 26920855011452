import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/compat/app';
import { ReactElement } from 'react';
import { Button, Card } from 'react-bootstrap';

export const FirebaseSignIn = (): ReactElement => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const login = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <Card className="m-2 p-4">
      <h4>Admin Sign-In</h4>
      <Button onClick={login} variant="success">
        <FontAwesomeIcon icon={faGoogle} className="me-1" />
        Sign In With google
      </Button>
    </Card>
  );
};
