import { api } from '../api';

type PresetWorkoutFromApi = {
  workoutId: string;
  displayName: string;
  description: string;
  targetMuscle: string[];
  variants: {
    name: string;
    displayName: string | null;
    description: string | null;
  }[];
};

export type Variant = {
  name: string;
  displayName: string;
  description: string;
};

export type Workout = {
  workoutId: string;
  displayName: string;
  description: string;
  targetMuscle: string[];
  variants: Variant[];
};

export type WorkoutVariableFlattened = {
  workoutId: string;
  displayName: string;
  description: string;
  targetMuscle: string[];
};

export const flattenVariant = (
  workouts: Workout[],
): WorkoutVariableFlattened[] => {
  return workouts
    .map((workout) =>
      workout.variants.map((variant) => ({
        workoutId: workout.workoutId + '-' + variant.name,
        displayName: variant.displayName ?? workout.displayName,
        description: variant.description ?? workout.description,
        targetMuscle: workout.targetMuscle,
      })),
    )
    .flat();
};

export const getAllWorkouts = async (): Promise<Workout[]> => {
  const result = await api.get<PresetWorkoutFromApi[]>('/preset/workouts');
  return result.data.map((item) => ({
    workoutId: item.workoutId,
    description: item.description,
    displayName: item.displayName,
    targetMuscle: item.targetMuscle,
    variants: item.variants.map((variant) => ({
      name: variant.name,
      displayName: variant.displayName || item.displayName,
      description: variant.description || item.description,
    })),
  }));
};

export const getWorkout = async (id: string): Promise<Workout | undefined> => {
  const workouts = await getAllWorkouts();
  return workouts.find((item) => item.workoutId === id);
};
