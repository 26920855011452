import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  faChartLine,
  faDatabase,
  faEdit,
  faLink,
  faList,
  faTag,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Breadcrumb, Button, Col, Row, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const Home = (): ReactElement => {
  const internalLinks: {
    title: string;
    links: {
      to: string;
      caption: string;
      icon: IconProp;
    }[];
  }[] = [
    {
      title: '機械学習',
      links: [
        { to: '/aws/record', caption: 'データ', icon: faDatabase },
        { to: '/aws/label', caption: 'ラベル', icon: faTag },
        { to: '/aws/model', caption: 'モデル', icon: faChartLine },
      ],
    },
    {
      title: 'マスタ管理',
      links: [
        { to: '/firebase/workout', caption: '筋トレ', icon: faList },
        { to: '/firebase/preset-menu', caption: 'メニュー', icon: faList },
      ],
    },
  ];

  const githubRepositories = [
    'mitou-ios_app',
    'mitou-api',
    'mitou-admin',
    'mitou-ml',
    'mitou-website',
    'mitou-fastlane',
  ];
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item active>Home</Breadcrumb.Item>
      </Breadcrumb>
      <h1>管理画面</h1>
      <Row>
        <Col xs={12} lg={7} xl={8}>
          {internalLinks.map(({ title, links }) => (
            <Card className="my-2">
              <Card.Body>
                <Card.Title>
                  <FontAwesomeIcon icon={faWrench} className="me-1" />
                  {title}
                </Card.Title>
                <Card.Text>
                  <Row xl={5} sm={4} xs={3}>
                    {links.map((link) => (
                      <Col className="p-1">
                        <Button
                          as={Link}
                          to={link.to}
                          style={{ width: '100%', height: '100%' }}
                          key={link.to}
                        >
                          <div>
                            <FontAwesomeIcon
                              icon={link.icon}
                              className="me-1"
                              size="2x"
                            />
                          </div>
                          <div className="mt-2">
                            <small>{link.caption}</small>
                          </div>
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </Col>
        <Col xs={12} lg={5} xl={4}>
          <Card className="my-2">
            <Card.Body>
              <Card.Title>
                <FontAwesomeIcon icon={faGithub} className="me-1" />
                Githubリポジトリ
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    <a
                      href={`https://github.com/kosuke-gin-ebi`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Organization(kosuke-gin-ebi)
                    </a>
                  </li>
                  {githubRepositories.map((repository) => (
                    <li key={repository}>
                      <a
                        href={`https://github.com/kosuke-gin-ebi/${repository}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {repository}
                      </a>
                      (
                      <a
                        href={`https://github.com/kosuke-gin-ebi/${repository}/issues`}
                        target="_blank"
                        rel="noreferrer"
                        className="mx-1"
                      >
                        issue
                      </a>
                      <a
                        href={`https://github.com/kosuke-gin-ebi/${repository}/issues/new`}
                        target="_blank"
                        rel="noreferrer"
                        className="mx-1"
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </a>
                      <a
                        href={`https://github.com/kosuke-gin-ebi/${repository}/pulls`}
                        target="_blank"
                        rel="noreferrer"
                        className="mx-1"
                      >
                        PR
                      </a>
                      <a
                        href={`https://github.com/kosuke-gin-ebi/${repository}/actions`}
                        target="_blank"
                        rel="noreferrer"
                        className="mx-1"
                      >
                        actions
                      </a>
                      )
                    </li>
                  ))}
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="my-2">
            <Card.Body>
              <Card.Title>
                <FontAwesomeIcon icon={faLink} className="me-1" />
                外部リンク
              </Card.Title>
              <Card.Text>
                <ul>
                  <li>
                    <a
                      href="https://console.aws.amazon.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AWS Console
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://drive.google.com/drive/u/0/folders/0AHlDhmYwcn-SUk9PVA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Google Drive
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.muscle-supporter.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Muscle Supporter ウェブサイト
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://scrapbox.io/muscle-supporter/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Scrapbox
                    </a>
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};
