import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { ReactElement, useEffect } from 'react';
import { Breadcrumb, Button, Form, Table } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getWorkout, Workout } from '../../../models/Workout';
import { Loading } from '../../common/Loading';

type Params = {
  id?: string;
};

export const PresetWorkoutDetail = (): ReactElement => {
  const params = useParams<Params>();

  const [item, setItem] = useState<Workout | null>(null);

  const reload = useCallback(async () => {
    if (!params.id) return;
    setItem(null);
    const res = await getWorkout(params.id);
    if (!res) {
      window.alert('404');
      return;
    }
    setItem(res);
  }, [params.id]);

  useEffect(() => {
    reload();
  }, [reload]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/firebase/workout' }}>
          筋トレ一覧
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {item?.displayName ?? '筋トレ詳細'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h1>{item?.displayName}</h1>
        <div></div>
        <div>
          <Button onClick={reload} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>
      {!item ? (
        <Loading />
      ) : (
        <Form>
          <Table bordered size="sm" responsive="sm">
            <tbody>
              <tr>
                {/* <th>workoutId</th> */}
                <th colSpan={2}>{item.workoutId}</th>
              </tr>
              <tr>
                <th>表示名</th>
                <td>{item.displayName}</td>
              </tr>
              <tr>
                <th>説明</th>
                <td>{item.description}</td>
              </tr>
              <tr>
                <th>対象部位</th>
                <td>{item.targetMuscle.join(' / ')}</td>
              </tr>
              {item.variants.map((variant) => (
                <>
                  <tr key={variant.name}>
                    {/* <th>name</th> */}
                    <th colSpan={2}>
                      {item.workoutId}-{variant.name}
                    </th>
                  </tr>
                  <tr key={variant.name + '-1'}>
                    <th>表示名</th>
                    <td>{variant.displayName}</td>
                  </tr>
                  <tr key={variant.name + '-2'}>
                    <th>説明</th>
                    <td>{variant.description}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>
        </Form>
      )}
    </div>
  );
};
