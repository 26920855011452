import {
  faAngleDoubleLeft,
  faAngleLeft,
  faCaretLeft,
  faAngleDoubleRight,
  faAngleRight,
  faCaretRight,
  faSearchMinus,
  faSearchPlus,
  faPlus,
  faEye,
  faEyeSlash,
  faSearch,
  faMousePointer,
  faArrowsAlt,
  faStepBackward,
  faStepForward,
  faToggleOn,
  faToggleOff,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import annotaionPlugin from 'chartjs-plugin-annotation';
import { ReactElement, useState } from 'react';
import { Button, ButtonToolbar, Col, InputGroup, Row } from 'react-bootstrap';
import { Chart, Line } from 'react-chartjs-2';

import { Loading } from '../common/Loading';

Chart.register(annotaionPlugin);

type Editing = 'begin' | 'end' | number | null;

type Props = {
  json: { [key: string]: number }[];
  start?: number;
  end?: number;
  countTS?: {
    ts: number;
  }[];
  light?: boolean;
  showAll: boolean;
  onMove?: (target: 'begin' | 'end' | number, to: number) => void;
  onCountAdd?: () => void;
};

const getGroup = (cols: string[]): string[][] => {
  const groups = Array.from(
    new Set(
      cols.map((col) => {
        return col.split(/\.(?=[^.]+$)/)[0];
      }),
    ),
  );
  return groups.map((group) =>
    cols.filter((col) => {
      const regex = new RegExp(group.replace('.', '\\.') + '\\.[^.]+$');
      return col.match(regex);
    }),
  );
};

export const getRangeFromData = (data: { ts: string }[]): [number, number] => {
  const sortedTs = data
    .map((data) => new Date(data.ts).getTime())
    .sort((a, b) => a - b);
  const dataMin = sortedTs[0] / 1000;
  const dataMax = sortedTs[sortedTs.length - 1] / 1000;
  return [dataMin, dataMax];
  //
};

export const DataGraph = (props: Props): ReactElement => {
  const data = props.json;

  // 軽量モード
  const [light, setLight] = useState<boolean>(true);

  const duration = props.start && props.end ? props.end - props.start : 0;

  // const sortedTs = props.json
  //   .map((data) => new Date(data.ts).getTime())
  //   .sort((a, b) => a - b);
  // const dataMin = sortedTs[0] / 1000;
  // const dataMax = sortedTs[sortedTs.length - 1] / 1000;

  const initialRange: [number, number] = [
    props.showAll
      ? getRangeFromData(data as unknown as { ts: string }[])[0]
      : (props.start ?? 0) - duration / 20,
    props.showAll
      ? getRangeFromData(data as unknown as { ts: string }[])[1]
      : (props.end ?? 0) + duration / 20,
  ];
  const [range, setRange] = useState<[number, number]>(initialRange);

  const resetZoom = () => {
    setRange(initialRange);
  };
  const zoom = (ratio: number) => {
    const midPoint = (range[0] + range[1]) / 2;
    const size = range[1] - range[0];
    setRange([midPoint - size / 2 / ratio, midPoint + size / 2 / ratio]);
  };
  const scroll = (ratio: number) => {
    const size = range[1] - range[0];
    setRange([range[0] + size * ratio, range[1] + size * ratio]);
  };

  const [editing, setEditing] = useState<Editing>('begin');
  const incrementEditing = () => {
    if (editing === 'begin') {
      if (props.countTS) {
        setEditing(0);
      }
    } else if (typeof editing === 'number') {
      if (editing + 1 < (props.countTS?.length ?? 0)) {
        setEditing(editing + 1);
      } else {
        setEditing('end');
      }
    }
  };
  const decrementEditing = () => {
    if (editing === 'end') {
      if (props.countTS) {
        setEditing(props.countTS.length - 1);
      }
    } else if (typeof editing === 'number') {
      if (editing - 1 >= 0) {
        setEditing(editing - 1);
      } else {
        setEditing('begin');
      }
    }
  };

  const move = (diff: number) => {
    if (!props.onMove) return;
    switch (editing) {
      case null:
        return;
      case 'begin':
        if (!props.start) return;
        props.onMove('begin', props.start + diff);
        return;
      case 'end':
        if (!props.end) return;
        props.onMove('end', props.end + diff);
        return;
      default:
        if (!props.countTS || props.countTS.length <= editing) return;
        props.onMove(editing, props.countTS[editing].ts + diff);
        return;
    }
  };

  const [visible, setVisible] = useState<{ [label: string]: number }>({});

  if (!data || data.length === 0) return <Loading />;

  const cols = Object.keys(data[0]).slice(2);
  const groups = getGroup(cols);
  const options = {
    responsive: true,
    animation: false,
    aspectRatio: 4,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'second',
          displayFormats: {
            second: 'HH:mm:ss',
          },
        },
        min: range[0] * 1000,
        max: range[1] * 1000,
      },
    },
    plugins: {
      annotation: {
        annotations: [
          ...(props.start
            ? [
                {
                  drawTime: 'beforeDraw',
                  type: 'box',
                  mode: 'vertical',
                  xMax: new Date(props.start * 1000),
                  borderColor: 'lightgray',
                  backgroundColor: 'lightgray',
                },
                {
                  drawTime: 'beforeDraw',
                  type: 'line',
                  mode: 'vertical',
                  xMin: new Date(props.start * 1000),
                  xMax: new Date(props.start * 1000),
                  borderColor: editing === 'begin' ? 'red' : 'black',
                },
              ]
            : []),
          ...(props.end
            ? [
                {
                  drawTime: 'beforeDraw',
                  type: 'box',
                  mode: 'vertical',
                  xMin: new Date(props.end * 1000),
                  borderColor: 'lightgray',
                  backgroundColor: 'lightgray',
                },
                {
                  drawTime: 'beforeDraw',
                  type: 'line',
                  mode: 'vertical',
                  xMin: new Date(props.end * 1000),
                  xMax: new Date(props.end * 1000),
                  borderColor: editing === 'end' ? 'red' : 'black',
                },
              ]
            : []),
          ...(props.countTS
            ? props.countTS.map((d, i) => {
                return {
                  drawTime: 'beforeDraw',
                  type: 'line',
                  mode: 'vertical',
                  xMin: new Date(d.ts * 1000),
                  xMax: new Date(d.ts * 1000),
                  borderColor: editing === i ? 'red' : 'grey',
                };
              })
            : []),
        ],
      },
    },
  };
  const colors = ['pink', 'cyan', 'yellow', 'lime', 'orange'];
  const dataLists = groups.map((group) => {
    const dataList = {
      datasets: group.map((col, i) => ({
        label: col,
        data: data
          .filter((row) => row[col])
          .filter(
            (_, i, arr) => !light || i % Math.floor(arr.length / 200) === 0,
          )
          .map((row) => ({
            x: row.ts,
            y: row[col],
          })),
        backgroundColor: colors[i % colors.length],
      })),
    };

    return {
      dataList,
      group,
    };
  });

  return (
    <div>
      <ButtonToolbar
        aria-label="Toolbar with button groups"
        style={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'white',
          zIndex: 100,
        }}
      >
        {!props.showAll && (
          <>
            <InputGroup className="me-2" aria-label="Second group">
              <Button
                variant={editing === 'begin' ? 'primary' : 'outline-primary'}
                onClick={() => {
                  setEditing('begin');
                }}
              >
                <FontAwesomeIcon icon={faStepBackward} />
              </Button>
              <Button variant="outline-primary" onClick={decrementEditing}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </Button>
              <InputGroup.Text
                style={{ width: 80 }}
                className="d-flex justify-content-between"
              >
                <FontAwesomeIcon icon={faMousePointer} />
                <div>
                  {typeof editing === 'number' ? editing + 1 : '-'}/
                  {props.countTS?.length ?? '-'}
                </div>
              </InputGroup.Text>
              <Button variant="outline-primary" onClick={incrementEditing}>
                <FontAwesomeIcon icon={faAngleRight} />
              </Button>
              <Button
                variant={editing === 'end' ? 'primary' : 'outline-primary'}
                onClick={() => {
                  setEditing('end');
                }}
              >
                <FontAwesomeIcon icon={faStepForward} />
              </Button>
            </InputGroup>
            <InputGroup className="me-2" aria-label="Second group">
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  move(-1);
                }}
              >
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
              </Button>
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  move(-0.1);
                }}
              >
                <FontAwesomeIcon icon={faAngleLeft} />
              </Button>
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  move(-0.01);
                }}
              >
                <FontAwesomeIcon icon={faCaretLeft} />
              </Button>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faArrowsAlt} />
              </InputGroup.Text>
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  move(0.01);
                }}
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </Button>
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  move(0.1);
                }}
              >
                <FontAwesomeIcon icon={faAngleRight} />
              </Button>
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  move(1);
                }}
              >
                <FontAwesomeIcon icon={faAngleDoubleRight} />
              </Button>
            </InputGroup>
            <InputGroup className="me-2" aria-label="Second group">
              <Button
                variant="outline-primary"
                onClick={(e) => {
                  props.onCountAdd?.();
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </InputGroup>
          </>
        )}
        <InputGroup className="me-2" aria-label="Second group">
          <Button
            variant="outline-primary"
            onClick={() => {
              scroll(-0.3);
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              zoom(1.4);
            }}
          >
            <FontAwesomeIcon icon={faSearchPlus} />
          </Button>
          <Button variant="outline-primary">
            <FontAwesomeIcon
              icon={faSearchMinus}
              onClick={() => {
                zoom(0.7);
              }}
            />
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              resetZoom();
            }}
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <Button
            variant="outline-primary"
            onClick={() => {
              scroll(0.3);
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </Button>
        </InputGroup>
        <InputGroup aria-label="Third group">
          <Button
            variant={light ? 'primary' : 'outline-primary'}
            onClick={() => {
              setLight(!light);
            }}
          >
            軽量
            <FontAwesomeIcon
              icon={light ? faToggleOn : faToggleOff}
              className="ms-1"
            />
          </Button>
        </InputGroup>
      </ButtonToolbar>
      <Row lg={1}>
        {dataLists.map(({ dataList, group }, i) => (
          <Col key={i}>
            <div>
              <div className="d-flex  align-items-top">
                <Button
                  variant="icon"
                  size="sm"
                  onClick={(e) =>
                    setVisible({
                      ...visible,
                      [i]: !visible[i],
                    })
                  }
                >
                  <FontAwesomeIcon icon={visible[i] ? faEye : faEyeSlash} />
                </Button>
                <div style={{ overflowX: 'hidden', textOverflow: 'ellipsis' }}>
                  {group.join(',')}
                </div>
              </div>
              {visible[i] ? (
                <Line options={options} data={dataList} />
              ) : (
                <div></div>
              )}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
  //
};
