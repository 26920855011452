import { faGithub } from '@fortawesome/free-brands-svg-icons';
import {
  faChartLine,
  faDatabase,
  faEdit,
  faHome,
  faList,
  faTag,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactElement } from 'react';
import { useEffect } from 'react';
import { Container, Nav, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter,
  Link,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { Header } from './Components/Header';
import { Home } from './Components/Home';
import { FileList } from './Components/aws/FileList';
import { Graph2 } from './Components/aws/Graph2';
import { MLModel } from './Components/aws/MLModel';
import { LabelCreate } from './Components/aws/label/LabelCreate';
import { LabelList } from './Components/aws/label/LabelList';
import { Loading } from './Components/common/Loading';
import { FirebaseSignIn } from './Components/firebase/FirebaseSignIn';
import { PresetMenuDetail } from './Components/firebase/presetMenu/PresetMenuDetail';
import { PresetMenuList } from './Components/firebase/presetMenu/PresetMenuList';
import { PresetWorkoutDetail } from './Components/firebase/workout/PresetWorkoutDetail';
import { WorkoutList } from './Components/firebase/workout/WorkoutList';
import route from './route';
import { RootState } from './store';
import { fetchPresetWorkouts } from './store/presetWorkout';

const App = (): ReactElement => {
  const firebaseUser = useSelector(
    (state: RootState) => state.firebaseAuth.user,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!firebaseUser) return;
    dispatch(fetchPresetWorkouts());
  }, [firebaseUser, dispatch]);

  const Body = (): ReactElement => {
    const githubRepositories = [
      'mitou-ios_app',
      'mitou-api',
      'mitou-ml',
      'mitou-website',
      'mitou-admin',
    ];

    const { pathname } = useLocation();
    return firebaseUser ? (
      <>
        <Header />
        <Container fluid>
          <Row>
            <Col
              xl={2}
              className="d-none d-xl-block bg-light p-3 "
              style={{ minHeight: '100vh' }}
            >
              <Nav
                defaultActiveKey="/home"
                className="flex-column"
                variant="pills"
              >
                <Nav.Link as={Link} to="/" active={pathname === '/'}>
                  <FontAwesomeIcon icon={faHome} className="me-1" />
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/aws/record"
                  active={pathname.startsWith('/aws/record')}
                >
                  <FontAwesomeIcon icon={faDatabase} className="me-1" />
                  収集データ確認
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/aws/label"
                  active={pathname.startsWith('/aws/label')}
                >
                  <FontAwesomeIcon icon={faTag} className="me-1" />
                  ラベリング
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/aws/model"
                  active={pathname.startsWith('/aws/model')}
                >
                  <FontAwesomeIcon icon={faChartLine} className="me-1" />
                  機械学習モデル
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/firebase/workout"
                  active={pathname.startsWith('/firebase/workout')}
                >
                  <FontAwesomeIcon icon={faList} className="me-1" />
                  筋トレ一覧
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/firebase/preset-menu"
                  active={pathname.startsWith('/firebase/preset-menu')}
                >
                  <FontAwesomeIcon icon={faList} className="me-1" />
                  メニュー管理
                </Nav.Link>
                <Nav.Item className="m-1">GitHub</Nav.Item>
                {githubRepositories.map((repository) => (
                  <Nav.Link
                    href={`https://github.com/kosuke-gin-ebi/${repository}/issues`}
                    target="_blank"
                    key={repository}
                    active={false}
                  >
                    <FontAwesomeIcon icon={faGithub} className="me-1" />
                    {repository}
                  </Nav.Link>
                ))}
                <Nav.Item className="m-1">Issueを作成</Nav.Item>
                {githubRepositories.map((repository) => (
                  <Nav.Link
                    href={`https://github.com/kosuke-gin-ebi/${repository}/issues/new`}
                    target="_blank"
                    key={repository}
                    active={false}
                  >
                    <FontAwesomeIcon icon={faEdit} className="me-1" />
                    {repository}
                  </Nav.Link>
                ))}
              </Nav>
            </Col>
            <Col>
              <div className="p-3">
                <Switch>
                  <Route
                    exact
                    path={route.firebase.presetWorktoutList.path}
                    component={WorkoutList}
                  />
                  <Route
                    exact
                    path={route.firebase.presetWorkoutDetail.path}
                    component={PresetWorkoutDetail}
                  />
                  <Route
                    exact
                    path={route.firebase.presetMenuList.path}
                    component={PresetMenuList}
                  />
                  <Route
                    exact
                    path={route.firebase.presetMenuAdd.path}
                    component={PresetMenuDetail}
                  />
                  <Route
                    exact
                    path={route.firebase.presetMenuDetail.path}
                    component={PresetMenuDetail}
                  />
                  <Route
                    exact
                    path={route.aws.mlDataList.path}
                    component={FileList}
                  />
                  <Route
                    exact
                    path={route.aws.mlDataGraph2.path}
                    component={Graph2}
                  />
                  <Route
                    exact
                    path={route.aws.mlModel.path}
                    component={MLModel}
                  />
                  <Route
                    exact
                    path={route.aws.labelList.path}
                    component={LabelList}
                  />
                  <Route
                    exact
                    path={route.aws.labelCreate.path}
                    component={LabelCreate}
                  />
                  <Route
                    exact
                    path={route.aws.labelDetail.path}
                    component={LabelCreate}
                  />
                  <Route component={Home} />
                </Switch>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    ) : firebaseUser === undefined ? (
      <Container>
        <Loading />
      </Container>
    ) : (
      <Container>
        <FirebaseSignIn />
      </Container>
    );
  };

  return (
    <>
      <BrowserRouter>
        <Body />
      </BrowserRouter>
    </>
  );
};

export default App;
