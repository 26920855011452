import { useState, useCallback, useEffect } from 'react';

import { api } from '../api';
import { SavedLabel } from './Label';

export type MLDataFromApi = {
  filename: string;
  date: string;
  time: string;
  label: string;
  uid?: string;
  uuid?: string;
  log?: string;
  labels: SavedLabel[];
};

export type MLDataLogWorkoutItem = {
  begin: string;
  end: string;
  recognizedWorkout?: string;
  recognizedWorkouts: {
    workoutId: string;
    probality: number;
  }[];
  confirmedWorkout: string;
  recognizedCount: number;
  confirmedCount: number;
};

export type MLDataLog = {
  type: 'INITIAL';
  list?: MLDataLogWorkoutItem[];
};

export type MLData = {
  filename: string;
  date: string;
  time: string;
  label: string;
  uid?: string;
  uuid?: string;
  log: MLDataLog[];
  labels: SavedLabel[];
};

export const getAllMLData = async (prefix: string): Promise<MLData[]> => {
  const res = await api.get<{ data: MLDataFromApi[] }>(
    `/auth/list?prefix=${prefix}`,
  );
  return res.data.data.map((item) => ({
    ...item,
    log: JSON.parse(item.log ?? '[]'),
  }));
};

export const getMLData = async (key: string): Promise<MLData> => {
  const res = await api.get<MLDataFromApi>(`/admin/mldata/get`, {
    params: { key },
  });
  return {
    ...res.data,
    log: JSON.parse(res.data.log ?? '[]'),
  };
};

export const useMLData = (
  key: string,
): { mlData: MLData | null; reload: () => void } => {
  const [mlData, setMLData] = useState<MLData | null>(null);
  const reload = useCallback(async () => {
    setMLData(null);
    if (!key) return;
    try {
      const res = await getMLData(key);
      setMLData(res);
    } catch (e) {
      setMLData(null);
    }
  }, [key]);
  useEffect(() => {
    reload();
  }, [reload]);
  return { mlData, reload };
};
