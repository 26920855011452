import {
  faSyncAlt,
  faTimesCircle,
  faCheckCircle,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { api } from '../../api';
import { Loading } from '../common/Loading';

type MLModelType = {
  version: string;
  is_released: string;
  timestamp?: string;
};

const useMLModel = () => {
  const [list, setList] = useState<MLModelType[] | null>(null);
  const reload = useCallback(async () => {
    setList(null);
    const res = await api.get<MLModelType[]>('/admin/admin/mlmodel/versions');
    setList(res.data);
  }, []);
  const updateReleased = useCallback(
    async (version: string, isReleased: boolean) => {
      await api.put<MLModelType[]>(`/admin/admin/mlmodel/versions/${version}`, {
        is_released: isReleased,
      });
      await reload();
    },
    [reload],
  );

  useEffect(() => {
    reload();
  }, [reload]);
  return { list, reload, updateReleased };
};

export const MLModel = (): ReactElement => {
  const { list, reload, updateReleased } = useMLModel();
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>機械学習モデル一覧</Breadcrumb.Item>
      </Breadcrumb>

      <div className="d-flex justify-content-between">
        <h1>機械学習モデル一覧</h1>
        <div>
          <Button onClick={reload} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>

      {list ? (
        <Table bordered hover size="sm" responsive="sm">
          <thead>
            <tr>
              <th>バージョン</th>
              <th>作成日</th>
              <th>有効</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list
              .slice()
              .reverse()
              .map((ver) => (
                <tr key={ver.version}>
                  <td>{ver.version}</td>
                  <td>
                    {ver.timestamp &&
                      format(
                        new Date(parseFloat(ver.timestamp) * 1000),
                        'yyyy/MM/dd HH:mm:ss',
                      )}
                  </td>
                  <td>
                    {ver.is_released ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTimesCircle}
                        className="text-danger"
                      />
                    )}
                  </td>
                  <td>
                    {ver.is_released ? (
                      <Button
                        onClick={() => {
                          updateReleased(ver.version, false);
                        }}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faEyeSlash} className="me-1" />
                        無効化
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          updateReleased(ver.version, true);
                        }}
                        size="sm"
                      >
                        <FontAwesomeIcon icon={faEye} className="me-1" />
                        有効化
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <Loading />
      )}
    </div>
  );
};
