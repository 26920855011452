const route = {
  home: {
    path: '/',
    to: (): string => '/',
  },
  firebase: {
    root: {
      path: '/firebase',
    },
    presetWorktoutList: {
      path: '/firebase/workout',
      to: (): string => '/firebase/workout',
    },
    presetWorkoutDetail: {
      path: '/firebase/workout/edit/:id',
      to: (id: string): string => `/firebase/workout/edit/${id}`,
    },
    presetMenuList: {
      path: '/firebase/preset-menu',
      to: (): string => '/firebase/preset-menu',
    },
    presetMenuDetail: {
      path: '/firebase/preset-menu/edit/:id',
      to: (id: string): string => `/firebase/preset-menu/edit/${id}`,
    },
    presetMenuAdd: {
      path: '/firebase/preset-menu/new',
      to: (): string => '/firebase/preset-menu/new',
    },
  },
  aws: {
    root: {
      path: '/aws',
    },
    mlDataList: {
      path: '/aws/record',
      to: (date?: string): string =>
        '/aws/record' + (date ? `?prefix=${date}` : ''),
    },
    mlDataGraph: {
      path: '/aws/record/graph',
      to: (key: string): string => `/aws/record/graph?file=${key}`,
    },
    mlDataGraph2: {
      path: '/aws/record/graph2',
      to: (key: string): string => `/aws/record/graph2?key=${key}`,
    },
    mlModel: {
      path: '/aws/model',
      to: (key: string): string => `/aws/model`,
    },
    labelCreate: {
      path: '/aws/label/create',
      to: (key: string): string => `/aws/label/create?key=${key}`,
    },
    labelList: {
      path: '/aws/label',
      to: (): string => '/aws/label',
    },
    labelDetail: {
      path: '/aws/label/edit/:timestamp',
      to: (timestamp: string): string => `/aws/label/edit/${timestamp}`,
    },
  },
};

export default route;
