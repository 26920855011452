export type MuscleName = {
  id: string;
  displayName: string;
};
export type MuscleCategory = MuscleName & {
  sub: MuscleName[];
};

export const muscleCategories: MuscleCategory[] = [
  {
    id: 'arm',
    displayName: '腕',
    sub: [
      {
        id: 'biceps_brachii',
        displayName: '上腕二頭筋',
      },
      {
        id: 'triceps_brachii',
        displayName: '上腕三頭筋',
      },
      {
        id: 'forearm',
        displayName: '前腕筋群',
      },
    ],
  },
  {
    id: 'chest',
    displayName: '胸',
    sub: [
      {
        id: 'pectoralis_major',
        displayName: '大胸筋',
      },
      {
        id: 'pectoralis_major_upper',
        displayName: '大胸筋上部',
      },
      {
        id: 'pectoralis_major_lower',
        displayName: '大胸筋下部',
      },
      {
        id: 'pectoralis_major_inside',
        displayName: '大胸筋内側',
      },
    ],
  },
  {
    id: 'shoulder',
    displayName: '肩',
    sub: [
      {
        id: 'deltoid',
        displayName: '三角筋',
      },
      {
        id: 'deltoid_anterior',
        displayName: '三角筋前部',
      },
      {
        id: 'deltoid_center',
        displayName: '三角筋中部',
      },
      {
        id: 'deltoid_rear',
        displayName: '三角筋後部',
      },
    ],
  },
  {
    id: 'back',
    displayName: '背中',
    sub: [
      {
        id: 'back_group',
        displayName: '背筋群',
      },
      {
        id: 'latissimus_dorsi',
        displayName: '広背筋',
      },
      {
        id: 'trapezius',
        displayName: '僧帽筋',
      },
      {
        id: 'erector_spinae',
        displayName: '脊柱起立筋',
      },
    ],
  },
  {
    id: 'abdominal',
    displayName: '腹',
    sub: [
      {
        id: 'abdominal_group',
        displayName: '腹筋群',
      },
      {
        id: 'rectus_abdominis',
        displayName: '腹直筋',
      },
      {
        id: 'oblique_abdominal',
        displayName: '腹斜筋',
      },
    ],
  },
  {
    id: 'leg',
    displayName: '脚',
    sub: [
      {
        id: 'quadriceps',
        displayName: '大腿四頭筋',
      },
      {
        id: 'hamstrings',
        displayName: 'ハムストリング',
      },
      {
        id: 'triceps_surae',
        displayName: '下腿三頭筋',
      },
      {
        id: 'gluteal_group',
        displayName: '臀筋群',
      },
      {
        id: 'gluteus_maximus',
        displayName: '大臀筋',
      },
      {
        id: 'gluteus_medius',
        displayName: '中臀筋',
      },
      {
        id: 'gluteus_minimus',
        displayName: '小臀筋',
      },
      {
        id: 'adductor_group',
        displayName: '内転筋群',
      },
    ],
  },
];

export const getTargetMuscleIndex = (muscle_id: string): number => {
  const mcmn = muscle_id.match(/^([^-]+)-(.+)/);
  if (mcmn != null && mcmn.length === 3) {
    let index_num = 0;
    muscleCategories
      .filter((category: MuscleCategory, index: number) => {
        if (category.id === mcmn[1]) index_num += index * 100;
        return category.id === mcmn[1];
      })[0]
      .sub.forEach((muscle: MuscleName, index: number) => {
        if (muscle.id === mcmn[2]) index_num += index;
      });
    return index_num;
  } else {
    return -1;
  }
};

export const getTargetMuscleName = (muscle_id: string): string => {
  const mcmn = muscle_id.match(/^([^-]+)-(.+)/);
  if (mcmn != null) {
    let ret = '';
    muscleCategories
      .filter((category: MuscleCategory) => {
        return category.id === mcmn[1];
      })[0]
      .sub.forEach((muscle: MuscleName) => {
        if (muscle.id === mcmn[2]) {
          ret = muscle.displayName;
        }
      });
    return ret;
  } else {
    return '';
  }
};

export default muscleCategories;
