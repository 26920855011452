import { faDownload, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { ReactElement, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Dropdown,
  DropdownButton,
  Table,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import {
  getAllLabels,
  getLabelCsv,
  SavedLabel,
  updateLabel,
} from '../../../models/Label';
import { getAllWorkouts, Workout } from '../../../models/Workout';
import route from '../../../route';
import { Loading } from '../../common/Loading';

export const LabelList = (): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [list, setList] = useState<SavedLabel[]>([]);
  const [workoutlist, setWorkoutlist] = useState<Workout[]>([]);

  const reload = async () => {
    setIsLoading(true);
    setList(await getAllLabels());
    setWorkoutlist(await getAllWorkouts());
    // updateVariantcolumn();
    setIsLoading(false);
  };

  const [sortby, setSortby] = useState<string>('timestamp');
  const sortSavedLabel = (sortby: string) => {
    switch (sortby) {
      case 'workoutId':
        return (a: SavedLabel, b: SavedLabel) => {
          return a.workoutId > b.workoutId ? 1 : -1;
        };
      case 'key':
        return (a: SavedLabel, b: SavedLabel) => {
          return a.key > b.key ? 1 : -1;
        };

      case 'timestamp':
      default:
        return (a: SavedLabel, b: SavedLabel) => {
          return parseFloat(a.timestamp) - parseFloat(b.timestamp);
        };
    }
  };

  const replaceHyphen = async (data: SavedLabel) => {
    data.workoutId = data.workoutId.replace('-', '_');
    await updateLabel({
      ...data,
    });
  };
  const replaceAllHyphen = () => {
    alert('workoutIdに含まれる全ての"-"を"_"で置換します、本当にいいですか？');
    Promise.all(
      list
        .filter((data) => {
          return data.workoutId.includes('-');
        })
        .map(async (data) => {
          replaceHyphen(data);
        }),
    );
  };
  const setFirstVariant = async (data: SavedLabel) => {
    const variant = workoutlist.filter((workout) => {
      return workout.workoutId === data.workoutId;
    })[0];
    if (typeof variant !== 'undefined' && !data.variant) {
      await updateLabel({
        ...data,
        variant: variant.variants[0].name,
      });
    }
  };
  const addAllVariant = () => {
    alert('variant未定義筋トレを１個目で初期化します、本当にいいですか？');
    Promise.all(
      list.map(async (data) => {
        setFirstVariant(data);
      }),
    );
  };

  useEffect(() => {
    reload();
  }, []);

  const downloadCsv = async (workoutId: string, timestamp: string) => {
    const { get_url } = await getLabelCsv(timestamp);

    const downloadLink = document.createElement('a');
    downloadLink.href = get_url;
    downloadLink.download = `${timestamp}-${workoutId}.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>機械学習ラベリング</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h1>機械学習ラベリング</h1>
        <div>
          <div className="d-flex">
            <DropdownButton
              id="dropdown-basic-button"
              title="バッチ処理"
              size="sm"
              className="mx-1"
            >
              <Dropdown.Item onClick={addAllVariant}>
                set all variant
              </Dropdown.Item>
              <Dropdown.Item onClick={replaceAllHyphen}>
                replace hyphen in workoutId
              </Dropdown.Item>
            </DropdownButton>
            <Button onClick={reload} size="sm" className="mx-1">
              <FontAwesomeIcon icon={faSyncAlt} />
            </Button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <Table bordered hover size="sm" responsive="sm">
          <thead>
            <tr>
              <th onClick={() => setSortby('timestamp')}>timestamp ▼</th>
              <th onClick={() => setSortby('workoutId')}>workoutId ▼</th>
              <th>variant</th>
              <th onClick={() => setSortby('key')}>key ▼</th>
              <th>uid</th>
              <th>duration</th>
              <th>count</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list.sort(sortSavedLabel(sortby)).map((data) => (
              <tr key={data.timestamp}>
                <td>
                  <Link to={route.aws.labelDetail.to(data.timestamp)}>
                    {data.timestamp}
                  </Link>
                </td>
                <td>{data.workoutId}</td>
                <td>{data.variant ? data.variant : ''}</td>
                <td>
                  <Link to={route.aws.mlDataGraph2.to(data.key)}>
                    {data.key}
                  </Link>
                </td>
                <td>{data.uid ?? '-'}</td>
                <td className="text-end px-1">
                  {(parseFloat(data.end) - parseFloat(data.start)).toFixed(1)}
                  sec
                </td>
                <td>
                  {data.countedTimeStamps ? data.countedTimeStamps.length : 0}
                </td>
                <td>
                  <Button
                    onClick={() => downloadCsv(data.workoutId, data.timestamp)}
                    size="sm"
                    className="mx-1"
                  >
                    <FontAwesomeIcon icon={faDownload} /> .csv
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};
