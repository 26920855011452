import axios, { AxiosResponse } from 'axios';
import firebase from 'firebase/compat/app';

import { API_URL } from './config';

export const api = axios.create({
  baseURL: API_URL,
});
api.interceptors.request.use(async (config) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: 'Bearer ' + (await getToken()),
    },
  };
});

const getToken = async (): Promise<string | null> =>
  (await firebase.auth().currentUser?.getIdToken()) ?? null;

export const getGraph = async (
  filename: string,
): Promise<AxiosResponse<{ get_url: string }>> => {
  return await api.get<{ get_url: string }>(`/auth/graph?path=${filename}`);
};

export const getCsvUrl = async (
  filename: string,
): Promise<{ get_url: string }> => {
  const res = await api.get<{ get_url: string }>(`/admin/download-csv`, {
    params: {
      key: filename,
    },
  });
  return res.data;
};

export const downloadCsv = async (filename: string): Promise<void> => {
  const { get_url } = await getCsvUrl(filename);

  const downloadLink = document.createElement('a');
  downloadLink.href = get_url;
  downloadLink.download = `${filename}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getCsv = async (
  filename: string,
): Promise<AxiosResponse<string>> => {
  const res = await api.get<{ get_url: string }>(`/admin/download-csv`, {
    params: {
      key: filename,
    },
  });
  return await axios.get<string>(res.data.get_url, { responseType: 'text' });
};
