import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { ReactElement, useState } from 'react';
import { Breadcrumb, Button, Spinner, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { getAllPresetMenu, PresetMenu } from '../../../models/PresetMenu';
import route from '../../../route';

export const PresetMenuList = (): ReactElement => {
  const [list, setList] = useState<PresetMenu[] | null>(null);

  const getData = async () => {
    setList(await getAllPresetMenu());
  };
  const reload = async () => {
    setList(null);
    await getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>メニュー管理</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h1>メニュー管理</h1>
        <div>
          <Button
            href="https://github.com/kosuke-gin-ebi/mitou-api/blob/main/api/preset.py"
            size="sm"
            target="_blank"
            className="mx-1"
            variant="secondary"
          >
            <FontAwesomeIcon icon={faGithub} className="me-1" />
            GitHub
          </Button>
          <Button onClick={reload} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>
      {list ? (
        <Table bordered hover size="sm" responsive="sm">
          <thead>
            <tr>
              <th>id</th>
              <th>表示名</th>
            </tr>
          </thead>
          <tbody>
            {list.map(
              (data) =>
                data.id && (
                  <tr key={data.id}>
                    <td>
                      <Link to={route.firebase.presetMenuDetail.to(data.id)}>
                        {data.id}
                      </Link>
                    </td>
                    <td>{data.displayName}</td>
                  </tr>
                ),
            )}
          </tbody>
        </Table>
      ) : (
        <div className="m-2">
          <Spinner animation="border" role="status"></Spinner>
        </div>
      )}
    </div>
  );
};
