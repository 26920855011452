import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type FirebaseAuthUser = {
  email: string;
};

export type FirebaseAuthState = {
  user: FirebaseAuthUser | null | undefined;
};

const initialState: FirebaseAuthState = {
  user: undefined,
};

export const firebaseAuthSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setFirebaseUser: (
      state,
      action: PayloadAction<FirebaseAuthUser | null>,
    ) => {
      state.user = action.payload;
    },
  },
});

export const { setFirebaseUser } = firebaseAuthSlice.actions;
