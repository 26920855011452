import {
  faCheck,
  faDownload,
  faPlus,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import queryString from 'query-string';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  Breadcrumb,
  Button,
  Form,
  FormControl,
  InputGroup,
  Table,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link, RouteProps, useLocation, useHistory } from 'react-router-dom';

import { downloadCsv } from '../../api';
import { getAllMLData, MLData } from '../../models/MLData';
import route from '../../route';
import { Loading } from '../common/Loading';

/**
 * prefix(2020-11-21など)を処理するhook
 */
const usePrefix = () => {
  const { search } = useLocation();
  const parsedPrefix = queryString.parse(search)['prefix'];
  const todayPrefix = dayjs().format('YYYY-MM-DD');

  const prefix = typeof parsedPrefix === 'string' ? parsedPrefix : todayPrefix;
  const nextDay = dayjs(prefix).add(1, 'day').format('YYYY-MM-DD');
  const prevDay = dayjs(prefix).add(-1, 'day').format('YYYY-MM-DD');
  return { todayPrefix, prefix, nextDay, prevDay };
};

/**
 * APIから一覧を取得するhook
 * @param prefix i.g. 2021-11-21 変更されると再読込します。
 */
const useFileList = (prefix: string) => {
  const [list, setList] = useState<MLData[] | null>(null);
  const reload = useCallback(async () => {
    setList(null);
    if (typeof prefix !== 'string') return;
    setList(await getAllMLData(prefix));
  }, [prefix]);
  useEffect(() => {
    reload();
  }, [reload]);

  return { list, reload };
};

export const FileList = (props: RouteProps): ReactElement => {
  const { todayPrefix, prefix, nextDay, prevDay } = usePrefix();
  const { list, reload } = useFileList(prefix);
  const history = useHistory();

  const [editingPrefix, setEditingPrefix] = useState<string>(prefix);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  useEffect(() => {
    setEditingPrefix(prefix);
    setIsEditing(false);
  }, [prefix]);

  const [includeFreedomMode, setIncludeFreedomMode] = useState<boolean>(true);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item active>収集データ確認</Breadcrumb.Item>
      </Breadcrumb>
      <h1>収集データ確認</h1>
      <div className="my-2 d-flex justify-content-between">
        <div className="d-flex align-items-center gap-2">
          {prevDay && <Link to={`?prefix=${prevDay}`}>&lt; {prevDay}</Link>}
          <InputGroup size="sm" style={{ width: 150 }}>
            <FormControl
              size="sm"
              type="text"
              placeholder="2021-07-19"
              value={editingPrefix}
              onChange={(e) => {
                setIsEditing(true);
                setEditingPrefix(e.currentTarget.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  history.push(`?prefix=${editingPrefix}`);
                }
              }}
            />
            {isEditing && (
              <Button
                variant="outline-secondary"
                as={Link}
                to={`?prefix=${editingPrefix}`}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            )}
          </InputGroup>
          <Link to={`?prefix=${todayPrefix}`}>今日</Link>
          {nextDay && <Link to={`?prefix=${nextDay}`}>{nextDay} &gt;</Link>}
        </div>
        <div>
          <Button type="submit" onClick={reload} size="sm">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>
      <Form.Check
        label="自動判別のデータも表示"
        checked={includeFreedomMode}
        onClick={() => setIncludeFreedomMode(!includeFreedomMode)}
        id="includeFreedomMode"
      />
      {list ? (
        <Table bordered hover size="sm" responsive="sm">
          <thead>
            <tr>
              <th>key</th>
              <th>uuid</th>
              <th>label</th>
              <th>ラベル数</th>
              <th>uid</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {list
              .filter(
                (data) => includeFreedomMode || data.label !== 'freedom mode',
              )
              .map((data) => (
                <tr key={data.filename}>
                  <td>
                    <Link to={route.aws.mlDataGraph2.to(data.filename)}>
                      {data.filename}
                    </Link>
                  </td>
                  <td>
                    {data.uuid && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id={data.uuid}>{data.uuid}</Tooltip>}
                      >
                        <code>{data.uuid.slice(0, 9)}...</code>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>{data.label}</td>
                  <td style={{ textAlign: 'right' }}>{data.labels.length}</td>
                  <td>
                    {data.uid && (
                      <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id={data.uid}>{data.uid}</Tooltip>}
                      >
                        <code>{data.uid.slice(0, 9)}...</code>
                      </OverlayTrigger>
                    )}
                  </td>
                  <td>
                    <Button
                      onClick={() => downloadCsv(data.filename)}
                      size="sm"
                      className="mx-1"
                    >
                      <FontAwesomeIcon icon={faDownload} /> .csv
                    </Button>
                    <Button
                      className="mx-1"
                      as={Link}
                      to={route.aws.labelCreate.to(data.filename)}
                      variant="success"
                      size="sm"
                    >
                      <FontAwesomeIcon icon={faPlus} /> label
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <div className="m-2">
          <Loading />
        </div>
      )}
    </div>
  );
};
