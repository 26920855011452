import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCaretLeft,
  faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';

type Props = {
  value: number;
  onChange: (val: number) => void;
  isInvalid?: boolean;
};
export const NumberInput = (props: Props): ReactElement => {
  return (
    <div className="d-flex">
      <Form.Control
        type="number"
        step="0.01"
        value={props.value}
        className="flex-grow-1"
        onInput={(e) => props.onChange(parseFloat(e.currentTarget.value))}
        isInvalid={!!props.isInvalid}
      />
      <ButtonGroup className="mx-1">
        <Button onClick={() => props.onChange(props.value - 1)}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </Button>
        <Button onClick={() => props.onChange(props.value - 0.1)}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
        <Button onClick={() => props.onChange(props.value - 0.01)}>
          <FontAwesomeIcon icon={faCaretLeft} />
        </Button>
      </ButtonGroup>
      <ButtonGroup className="mx-1">
        <Button onClick={() => props.onChange(props.value + 0.01)}>
          <FontAwesomeIcon icon={faCaretRight} />
        </Button>
        <Button onClick={() => props.onChange(props.value + 0.1)}>
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
        <Button onClick={() => props.onChange(props.value + 1)}>
          <FontAwesomeIcon icon={faAngleDoubleRight} />
        </Button>
      </ButtonGroup>
    </div>
  );
};
