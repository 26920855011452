import {
  faSave,
  faSync,
  faTrash,
  faPlus,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import 'chartjs-adapter-moment';
import csvJSON from 'csvtojson';
import queryString from 'query-string';
import { useMemo } from 'react';
import { ReactElement, useEffect, useState } from 'react';
import { Breadcrumb, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteProps, useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { getCsv } from '../../../api';
import {
  createLabel,
  deleteLabel,
  getLabel,
  updateLabel,
} from '../../../models/Label';
import { useMLData } from '../../../models/MLData';
import { Workout } from '../../../models/Workout';
import route from '../../../route';
import { RootState } from '../../../store';
import { fetchPresetWorkouts } from '../../../store/presetWorkout';
import { Loading } from '../../common/Loading';
import { getTargetMuscleIndex } from '../../firebase/workout/muscleCategories';
import { DataGraph, getRangeFromData } from '../DataGraph';
import { MLDataInfo } from '../MLDataInfo';
import { NumberInput } from './NumberInput';

type Params =
  | {
      key: string;
      timestamp: string;
    }
  | Record<string, never>;

type CountedTimeStamp = {
  ts: number;
};

type ValidatedFormData = {
  workoutId: string;
  variant: string;
  start: number;
  end: number;
  countedTimeStamps: CountedTimeStamp[];
};

export const LabelCreate = (props: RouteProps): ReactElement => {
  const params = useParams<Params>();
  const history = useHistory();
  const propKey = queryString.parse(props.location?.search ?? '')['key'];
  const dispatch = useDispatch();

  const workoutList = useSelector((state: RootState) => state.presetWorkout);

  const [key, setKey] = useState<string>('');
  const { mlData, reload: reloadMLData } = useMLData(key);

  useEffect(() => {
    if (!params.timestamp) {
      if (!propKey || Array.isArray(propKey)) return;
      setKey(propKey);
    } else {
      getLabel(params.timestamp)
        .then((res) => {
          setKey(res.key);
          setDefeaultValues({
            ...res,
            start: parseFloat(res.start),
            end: parseFloat(res.end),
            countedTimeStamps:
              res.countedTimeStamps?.map((x) => {
                const tmp: CountedTimeStamp = {
                  ts: parseFloat(x),
                };
                return tmp;
              }) ?? [],
          });
          const arr: string[] = [];
          const selectedWorkout = workoutList.workouts.filter((workout) => {
            return workout.workoutId === res.workoutId;
          })[0];
          if (typeof selectedWorkout !== 'undefined') {
            selectedWorkout.variants.forEach((variant) => {
              arr.push(variant.name);
            });
            setNowVariant([...arr]);
          }
        })
        .catch((err) => {
          window.alert('api Error');
        });
    }
  }, [params, propKey, workoutList.workouts]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[] | null>(null);

  const { timeMin, timeMax } = useMemo(() => {
    if (!data) return { timeMin: null, timeMax: null };
    return {
      timeMin: Math.min(
        ...data.map((row) => new Date(row.ts).getTime() / 1000),
      ),
      timeMax: Math.max(
        ...data.map((row) => new Date(row.ts).getTime() / 1000),
      ),
    };
  }, [data]);

  const schema = useMemo(
    () =>
      yup.object().shape({
        workoutId: yup
          .string()
          .matches(/^[0-9a-z_]+$/)
          .required(),
        variant: yup.string(),
        start: yup
          .number()
          .min(timeMin || 0)
          .max(timeMax || 0),
        end: yup
          .number()
          .min(timeMin || 0)
          .max(timeMax || 0),
        countedTimeStamps: yup.array(
          yup.object().shape({
            ts: yup
              .number()
              .min(timeMin || 0)
              .max(timeMax || 0),
          }),
        ),
      }),
    [timeMin, timeMax],
  );

  const [defaultValues, setDefeaultValues] = useState<ValidatedFormData>({
    workoutId: '',
    variant: '',
    start: 0,
    end: 0,
    countedTimeStamps: [],
  });
  const { register, handleSubmit, setValue, reset, watch, formState, control } =
    useForm({
      defaultValues,
      resolver: yupResolver(schema),
    });
  const { fields, append, remove } = useFieldArray({
    name: 'countedTimeStamps',
    control,
  });

  // 変更をハンドルする
  const onMove = (target: 'begin' | 'end' | number, to: number) => {
    switch (target) {
      case 'begin':
        setValue('start', to);
        return;
      case 'end':
        setValue('end', to);
        return;
      default:
        setValue(`countedTimeStamps.${target}.ts`, to);
        return;
    }
  };

  // カウントの追加をハンドルする
  const onCountAdd = () => {
    const ts = watch(`countedTimeStamps.${fields.length - 1}.ts`)
      ? watch(`countedTimeStamps.${fields.length - 1}.ts`)
      : timeMin;
    if (!ts) return;
    append({
      ts: ts + 0.1,
    });
  };

  useEffect(() => {
    if (params.timestamp) return; // create only
    setDefeaultValues({
      workoutId: '',
      variant: '',
      start: timeMin || 0,
      end: timeMax || 0,
      countedTimeStamps: [{ ts: timeMin || 0 }],
    });
  }, [timeMin, timeMax, params.timestamp]);
  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [nowVariant, setNowVariant] = useState<string[]>(['']);

  const save = async (data: ValidatedFormData) => {
    if (isSaving) return;
    setIsSaving(true);
    if (!key || Array.isArray(key)) return;
    if (!params.timestamp) {
      // create
      await createLabel({
        ...data,
        variant: data.variant,
        start: data.start.toString(),
        end: data.end.toString(),
        countedTimeStamps: data.countedTimeStamps.map((x) => x.ts.toString()),
        key,
        uid: mlData?.uid,
      });
      history.push(route.aws.mlDataList.to());
    } else {
      // save
      await updateLabel({
        ...data,
        variant: data.variant,
        start: data.start.toString(),
        end: data.end.toString(),
        countedTimeStamps: data.countedTimeStamps
          ? data.countedTimeStamps.map((x) => x.ts.toString())
          : [],
        timestamp: params.timestamp,
        key,
      });
    }
    setIsSaving(false);
  };

  const deleteItem = async () => {
    if (!params.timestamp) return;
    if (!window.confirm('削除しますか？')) return;
    await deleteLabel(params.timestamp);
    history.push(route.aws.labelList.to());
  };

  //csvの読み込み
  useEffect(() => {
    if (!key) return;
    getCsv(key)
      .then((res) => {
        const csv = res.data;
        csvJSON({
          flatKeys: true,
        })
          .fromString(csv)
          .then((json) => {
            if (!params.timestamp) {
              // create
              const range = getRangeFromData(json);
              setValue('start', range[0]);
              setValue('end', range[1]);
            }
            setData(json);
          });
      })
      .catch((err) => {
        window.alert('csv Error');
      });
  }, [key, params.timestamp, setValue]);

  if (!data || !timeMin || !timeMax) return <Loading />;

  const idleWorkout: Workout[] = [
    {
      workoutId: 'idle',
      displayName: 'idle',
      description: '',
      targetMuscle: [''],
      variants: [
        {
          name: 'sitting',
          displayName: '休憩中',
          description: '',
        },
        {
          name: 'standing',
          displayName: '待機中',
          description: '',
        },
        {
          name: 'walking',
          displayName: '移動中',
          description: '',
        },
      ],
    },
  ];

  const sortWorkout = (a: Workout, b: Workout) => {
    return (
      getTargetMuscleIndex(a.targetMuscle[0]) -
      getTargetMuscleIndex(b.targetMuscle[0])
    );
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/aws/label' }}>
          機械学習ラベリング
        </Breadcrumb.Item>
        <Breadcrumb.Item active>ラベル編集</Breadcrumb.Item>
      </Breadcrumb>
      <h2>ラベル編集</h2>
      <div className="d-flex justify-content-between">
        <h4>元データ情報</h4>
        <div></div>
        <div>
          <Button onClick={reloadMLData} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>

      {mlData ? <MLDataInfo mlData={mlData} /> : <Loading />}
      <h4>ラベル</h4>
      <Form onSubmit={handleSubmit(save)}>
        <Row>
          <Form.Group as={Col} xs={12} lg={6}>
            <Form.Label>筋トレ</Form.Label>
            <div className="d-flex">
              <div className="flex-fill">
                <Form.Select
                  {...register('workoutId')}
                  isInvalid={!!formState.errors.workoutId}
                  size="sm"
                  onChange={(e) => {
                    const newId = e.currentTarget.value;
                    const workout = workoutList.workouts
                      .concat(idleWorkout)
                      .find((item) => item.workoutId === newId);
                    if (!workout) {
                      setNowVariant([]);
                    } else {
                      setNowVariant(
                        workout.variants.map((variant) => variant.name),
                      );
                    }
                  }}
                >
                  <option value="">-- select --</option>
                  {workoutList.workouts
                    .concat(idleWorkout)
                    .sort(sortWorkout)
                    .map((workout) => (
                      <option value={workout.workoutId} key={workout.workoutId}>
                        {workout.displayName}
                      </option>
                    ))}
                </Form.Select>
              </div>
              <div>
                <Button
                  className="mx-1"
                  variant="light"
                  onClick={() => {
                    dispatch(fetchPresetWorkouts());
                  }}
                  size="sm"
                >
                  {workoutList.isLoading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <FontAwesomeIcon icon={faSync} />
                  )}
                </Button>
              </div>
            </div>
          </Form.Group>
          <Form.Group as={Col} xs={12} lg={6}>
            <Form.Label>variant</Form.Label>
            <Form.Select
              {...register('variant')}
              isInvalid={!!formState.errors.variant}
              size="sm"
            >
              <option value="">-- select --</option>
              {nowVariant.map((variant) => (
                <option value={variant} key={variant}>
                  {variant}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <details>
            <summary>直接入力</summary>
            <div className="d-flex justify-content-between">
              <Form.Group>
                <Form.Label>start</Form.Label>
                <NumberInput
                  value={watch('start') || 0}
                  onChange={(v) => setValue('start', v)}
                  isInvalid={!!formState.errors.start}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>end</Form.Label>
                <NumberInput
                  value={watch('end') || 0}
                  onChange={(v) => setValue('end', v)}
                  isInvalid={!!formState.errors.end}
                />
              </Form.Group>
            </div>
            <hr />
            {fields.map((field, index) => (
              <Form.Group key={index}>
                <Form.Label>count_{index + 1}</Form.Label>
                <NumberInput
                  value={watch(`countedTimeStamps.${index}.ts`) || 0}
                  onChange={(v) => setValue(`countedTimeStamps.${index}.ts`, v)}
                  isInvalid={!!formState.errors.countedTimeStamps}
                />
              </Form.Group>
            ))}
            <div className="mt-3 d-flex justify-content-between">
              <div>
                <Button
                  className="mx-1"
                  variant="secondary"
                  type="button"
                  onClick={() =>
                    append({
                      ts: watch(`countedTimeStamps.${fields.length - 1}.ts`)
                        ? watch(`countedTimeStamps.${fields.length - 1}.ts`)
                        : timeMin,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faPlus} /> カウント追加
                </Button>
              </div>
              <div>
                <Button
                  className="mx-1"
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    remove(fields.length - 1);
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} /> カウント削除
                </Button>
              </div>
            </div>
          </details>
        </Row>
        <div className="mt-3 d-flex justify-content-between">
          <div>
            <Button className="mx-1" variant="success" type="submit">
              <FontAwesomeIcon icon={faSave} /> 保存
              {isSaving && (
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="ms-1"
                />
              )}
            </Button>
          </div>
          <div>
            <Button className="mx-1" variant="danger" onClick={deleteItem}>
              <FontAwesomeIcon icon={faTrash} /> 削除
            </Button>
          </div>
        </div>
      </Form>
      <hr />
      <DataGraph
        json={data}
        start={watch('start')}
        end={watch('end')}
        countTS={fields.map((field, index) => {
          return { ts: watch(`countedTimeStamps.${index}.ts`) };
        })}
        onMove={onMove}
        onCountAdd={onCountAdd}
        showAll={false}
      />
    </div>
  );
};
