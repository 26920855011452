import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { faSignOutAlt, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import firebase from 'firebase/compat/app';
import { ReactElement } from 'react';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ApiEnv, nodeIsProd } from '../config';
import { RootState } from '../store';

export const Header = (): ReactElement => {
  const envColor = (() => {
    switch (ApiEnv) {
      case 'dev':
        return 'success';
      case 'stg':
        return 'warning';
      case 'prod':
        return 'danger';
    }
  })();

  const signOutFirebase = () => {
    firebase.auth().signOut();
  };

  const firebaseUser = useSelector(
    (state: RootState) => state.firebaseAuth.user,
  );
  return (
    <Navbar expand="md" bg={envColor} variant="dark">
      <Container fluid className="mx-3">
        <Navbar.Brand as={Link} to="/">
          <FontAwesomeIcon icon={faWrench} className="me-1" />
          admin ({ApiEnv})
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="ms-auto">
            {firebaseUser ? (
              <NavDropdown
                title={firebaseUser.email}
                id="firebase-auth-nav-dropdown"
              >
                <NavDropdown.Item onClick={signOutFirebase}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  ログアウト
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link as={Link} to="/firebase">
                <FontAwesomeIcon icon={faGoogle} className="me-1" />
                ログイン
              </Nav.Link>
            )}
            {nodeIsProd ? (
              <NavDropdown title={ApiEnv} id="basic-nav-dropdown">
                <NavDropdown.Item href="https://dev.admin.v1.muscle-supporter.com">
                  dev
                </NavDropdown.Item>
                <NavDropdown.Item href="https://admin.v1.muscle-supporter.com">
                  prod
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link>{ApiEnv}</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
