import { faDownload, faPlus, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'chartjs-adapter-moment';
import csvJSON from 'csvtojson';
import queryString from 'query-string';
import { ReactElement, useEffect, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { Link, RouteProps } from 'react-router-dom';

import { downloadCsv, getCsv } from '../../api';
import { useMLData } from '../../models/MLData';
import route from '../../route';
import { Loading } from '../common/Loading';
import { DataGraph } from './DataGraph';
import { MLDataInfo } from './MLDataInfo';

export const Graph2 = (props: RouteProps): ReactElement => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[] | null>(null);

  const propKey = queryString.parse(props.location?.search ?? '')['key'];
  const key = typeof propKey === 'string' ? propKey : '';

  const { mlData, reload: reloadMLData } = useMLData(key);

  useEffect(() => {
    if (typeof key !== 'string') return;
    getCsv(key)
      .then((res) => {
        const csv = res.data;
        csvJSON({
          flatKeys: true,
        })
          .fromString(csv)
          .then((json) => {
            setData(json);
          });
      })
      .catch((err) => {
        window.alert('API Error');
      });
  }, [key]);

  if (typeof key !== 'string') return <div>404</div>;
  if (!data) return <Loading />;
  const matches = key.match(/(\d{4}-\d{2}-\d{2})\/.+/);
  const day = matches && matches.length > 1 ? matches[1] : '';

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: `/aws/record?prefix=${day}` }}
        >
          収集データ確認({day})
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{key}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h1>データ詳細</h1>
        <div>
          <Button onClick={() => downloadCsv(key)} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faDownload} /> csv ダウンロード
          </Button>
          <Button
            className="mx-1"
            as={Link}
            to={route.aws.labelCreate.to(key)}
            variant="success"
            size="sm"
          >
            <FontAwesomeIcon icon={faPlus} /> ラベル作成
          </Button>
          <Button onClick={() => reloadMLData()} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </div>
      </div>
      {mlData ? <MLDataInfo mlData={mlData} /> : <Loading />}
      <DataGraph json={data} showAll={true} />
    </div>
  );
};
