import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useState } from 'react';
import { ReactElement, useEffect } from 'react';
import { Breadcrumb, Button, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { getPresetMenu, PresetMenu } from '../../../models/PresetMenu';
import { WorkoutVariableFlattened } from '../../../models/Workout';
import { RootState } from '../../../store';
import { Loading } from '../../common/Loading';

type Params = {
  id?: string;
};

const usePrestMenuDetail = (id: string) => {
  const [menu, setMenuList] = useState<PresetMenu | null>(null);
  const reload = useCallback(async () => {
    setMenuList(null);
    const res = await getPresetMenu(id);
    setMenuList(res);
  }, [id]);
  useEffect(() => {
    reload();
  }, [reload]);
  return { menu, reload };
};

export const PresetMenuDetail = (): ReactElement => {
  const params = useParams<Params>();
  const { menu, reload } = usePrestMenuDetail(params.id ?? '');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const workoutList = useSelector((state: RootState) => state.presetWorkout);
  const getWorkout = (workoutId: string): WorkoutVariableFlattened | null => {
    if (workoutList.isLoading) return null;
    const workouts = workoutList.workoutsWithVariant;
    return workouts.find((item) => item.workoutId === workoutId) ?? null;
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: '/firebase/preset-menu' }}
        >
          メニュー管理
        </Breadcrumb.Item>
        <Breadcrumb.Item active>
          {menu?.displayName ?? 'メニュー編集'}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between">
        <h1>メニュー編集</h1>
        <div>
          <Button onClick={reload} size="sm" className="mx-1">
            <FontAwesomeIcon icon={faSyncAlt} />
          </Button>
        </div>
      </div>
      {menu ? (
        <>
          <Table bordered size="sm" responsive="sm">
            <tbody>
              <tr>
                <th>id</th>
                <td>
                  <code>{menu.id}</code>
                </td>
              </tr>
              <tr>
                <th>表示名</th>
                <td>{menu.displayName}</td>
              </tr>
              <tr>
                <th>所要時間</th>
                <td>
                  {Math.floor(menu.estimatedDuration / 60)}'
                  {menu.estimatedDuration % 60}"
                </td>
              </tr>
            </tbody>
          </Table>
          <Table bordered size="sm" responsive="sm">
            <thead>
              <tr>
                <th>worktoutId</th>
                <th>回数</th>
                <th>所要時間</th>
              </tr>
            </thead>
            <tbody>
              {menu.workouts.map((workout, i) => (
                <tr key={i}>
                  <td>
                    {getWorkout(workout.workoutId)?.displayName ??
                      workout.workoutId}
                  </td>
                  <td>{workout.reps}</td>
                  <td>{workout.estimatedDuration}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};
