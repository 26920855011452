import 'firebase/compat/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import { firebaseConfig } from './config';
import { getAdmins } from './models/Admin';
import { FirebaseAuthUser, setFirebaseUser } from './store/firebaseAuth';
import { store } from './store/index';

firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().onAuthStateChanged(async (user: firebase.User | null) => {
  if (!user) {
    store.dispatch(setFirebaseUser(null));
  } else {
    try {
      await getAdmins();
      const firebaseUser: FirebaseAuthUser = {
        email: user.email ?? '',
      };
      store.dispatch(setFirebaseUser(firebaseUser));
    } catch (e) {
      await firebase.auth().signOut();
      store.dispatch(setFirebaseUser(null));
    }
  }
});

export const firestore = firebase.firestore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
