import { api } from '../api';

export type PresetMenuWorkout = {
  workoutId: string;
  reps: number;
  estimatedDuration: number;
};

export type PresetMenu = {
  id?: string;
  displayName: string;
  estimatedDuration: number;
  workouts: PresetMenuWorkout[];
};

export const validatePresetMenu = (
  menu: Partial<PresetMenu>,
): menu is PresetMenu => {
  if (!menu.displayName) return false;
  if (!menu.estimatedDuration) return false;
  if (!menu.workouts) return false;
  if (menu.workouts.length === 0) return false;
  return true;
};

export const getAllPresetMenu = async (): Promise<PresetMenu[]> => {
  const res = await api.get<PresetMenu[]>('/preset/menus');
  return res.data;
};

export const getPresetMenu = async (id: string): Promise<PresetMenu | null> => {
  const menus = await getAllPresetMenu();
  return menus.find((item) => item.id === id) ?? null;
};
